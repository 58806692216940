@tailwind base;

@tailwind components;

@tailwind utilities;


.theme-normal {
  --color-text-primary: rgba(255,255,255,1);
  --color-text-default: rgba(255,255,255,1);
  --color-text-secondary: rgba(100,100,100,1);
  --color-text-inverse: rgba(56, 56, 56, 1);
  --color-bg-primary: rgba(56, 56, 56, 1);
  --color-bg-accent: rgba(195, 228, 82, 1);
  --color-bg-default: rgba(56, 56, 56, 1);
  --color-bg-inverse: rgba(255,255,255,1);
  --font-weight-normal: 600;
  --font-weight-thick: 700;
  --radius-normal:0.5rem;


}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
