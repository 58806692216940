.spinner {
    animation: spinning .8s linear infinite;
}




@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
